<template>
  <div class="max-w-7xl mx-auto px-6 py-12">
  <article class="prose max-w-none">
  <h2>About</h2>
  <p>
 This is a weekly competition. 
The winners will be announced on the Results page every week insha ALLAH.
  </p>

<h3>Competition motivation!</h3>


<h3>Quran [ 2 : 148 ] : --- So compete with one another in doing good ---</h3>

<p><b>FAQ</b> - How much time will it take? Signing up is super easy and quick! Once that is done, you can literally compete even for a total of a minute or two every week!</p>


<h3>A bit about the competitions!</h3>
<p>All members are asked to compete with anonymous usernames (for example; “Happy_ Muslim_92” ) so that there is no intention of showing off.</p>

<h3>Tasbih competition</h3>
<p>We have made an online Tasbih for you with some short but very powerful Adhkar.</p>

<h3>Mindful Minutes competition</h3>
There are three categories:
<ol>
  <li>Quran recitation (time in min)</li>
  <li>Quran - listening to Audio / reading translation (time in min)</li>
  <li>Time spent (in min) watching an Islamic video / lecture</li>
</ol>

<h3>Sadaqah (charity) competition</h3>
<p>This is also a weekly competition.</p>

<h3>Fitness competition</h3>
<p>Abu Huraira reported Allah's Messenger (peace and blessings of Allah be upon him) as saying:</p>
<figure>
<blockquote>
A strong believer is better and is more lovable to Allah than a weak believer, and there is good in everyone,--- [Sahih Muslim 2664]
</blockquote>
</figure>
This strength includes both physical strength and strength of faith.

<p>It is soundly narrated from the Prophet (PBUH) that he said:</p>
<figure>
<blockquote>
“Everything in which there is no remembrance of Allah is idle play, except four things: a man playing with his wife; a man training his horse; a man running between two lines (as in a race); and a man learning how to swim.”
</blockquote>
<i>(Narrated by an-Nasaa’i in as-Sunan al-Kubra (8889); classed as saheeh by al-Albaani in as-Saheehah (315).)</i>
</figure>

<p>As far as the weekly competition is concerned, there are several categories like pushups, jogging distance (km), sit-ups etc.</p>


<h3>Introducing Competition Mode!</h3>
<p>The figure in brackets next to your count is the next highest score for that category. Once you overtake that, the next highest score will be shown. This will continue till you are the leader 😊</p>
 
  </article>
  </div>
</template>

<style>
.prose {
}
</style>