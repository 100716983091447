<template>
  <div>
    <!-- <BaseAlert storage-id="hide_alert_username" type="warning"
      ><AlertUsername />
    </BaseAlert>
    <BaseAlert storage-id="hide_alert_updates" type="info"
      ><AlertUpdates />
    </BaseAlert>
    <BaseAlert storage-id="hide_alert_bugs" type="info"><AlertBugs /> </BaseAlert> -->
  </div>
</template>

<script>
// import AlertUsername from "@/components/alerts/AlertUsername.vue";
// import AlertUpdates from "@/components/alerts/AlertUpdates.vue";
// import AlertBugs from "@/components/alerts/AlertBugs.vue";
// import BaseAlert from "@/components/shared/BaseAlert.vue";

export default {
  components: {
    // BaseAlert,
    // AlertUsername,
    // AlertUpdates,
    // AlertBugs,
  },
};
</script>

<style>
</style>