<template>
  <div>
    <page-heading title="Weekly Team Challenges"></page-heading>
    <main>
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <p class="text-gray-700 italic mb-6">
          These are the weekly challenges. Everyone's points go towards these
          goals, so let's work together to achieve them!
        </p>
        <loader v-if="!loaded"></loader>
        <template v-else>
          <div v-for="(challenge, i) in challenges" :key="challenge.id">
            <ProgressBar
              :class="{ 'mt-12': i > 0 }"
              :label="competitionKeys[challenge.id].title"
              :current="totalCum[challenge.id]"
              :total="challenge.goal"
            />
          </div>
        </template>
      </div>
    </main>
  </div>
</template>

<script>
import PageHeading from "../components/helpers/page.heading.vue";
import Loader from "../components/helpers/loader.vue";
import ProgressBar from "@/components/ProgressBar";
import { competitionKeys } from "../data";

export default {
  components: {
    PageHeading,
    Loader,
    ProgressBar,
  },
  data() {
    return {
      challenges: [
        { id: "dhikr_4", goal: 10000 },
        { id: "dhikr_5", goal: 10000 },
        { id: "fitness_1", goal: 500 },
        { id: "mindful_2", goal: 120 },
      ],
    };
  },
  computed: {
    competitionKeys() {
      return competitionKeys;
    },
    startDate() {
      return this.$store.getters.orderedByScore.start;
    },
    orderedByScore() {
      return this.$store.getters.orderedByScore.data;
    },
    totalCum() {
      return this.$store.getters.totalCum.data;
    },
    showResults() {
      return this.orderedByScore.length > 0;
    },
    loaded() {
      return this.$store.state.Result.loadedResults;
    },
  },
  mounted() {
    this.$store.dispatch("loadChallenges");
  },
};
</script>